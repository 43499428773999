import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";
import { Show } from "solid-js";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";

export default function Enga10Page() {
  return (
    <>
      <article class="page-engagement page-engagement-10">
        <FirstScreen
          visual="/images/engagements/page-engagement-10-visual.jpg"
          visualMobile="/images/engagements/page-engagement-10-visual-mobile.jpg"
          title="Économiser l’eau"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-batiments-plus-respectueux-de-l-environnement.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/"
          isLastEnga
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
        </section>

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/",
            )}
            class="btn"
          >
            Nos engagements{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const [viewportSizeProvider] = useViewportSizeContext();

  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour l’environnement</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est faire en sorte que
            toutes nos douches et WC soient équipés de systèmes toujours plus
            économes en eau.
          </p>
        </div>

        <div class="media-part infography">
          <Show
            when={viewportSizeProvider.viewPortIsLessThan768}
            fallback={
              <img
                src={imgProxy(
                  "/images/engagements/page-engagement-10-infographie-desktop.jpg",
                  `size:720:420/resizing_type:fill`,
                )}
                alt=""
                height="420"
                width="720"
                loading="lazy"
              />
            }
          >
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-10-infographie-mobile.jpg",
                `size:720:1280/resizing_type:fill`,
              )}
              alt=""
              height="1280"
              width="720"
              loading="lazy"
            />
          </Show>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des baignoires et douches économes en eau</h3>
          <p>
            Toutes les baignoires et les douches sont équipées de mitigeurs
            thermostatiques permettant des économies d’eau importantes,
            l’alimentation basse pression avec débit limité en base pouvant
            générer jusqu’à 25 % d’économie d’eau.
          </p>
          <p>
            Tous les modèles sélectionnés sont équipés d’un système limitant le
            débit (en général compris entre 5 et 6 l/mn pour les robinets et
            jamais plus de 10 l/mn pour les douchettes)<sup>1</sup>.
          </p>
          <p class="legend">
            <sup>1</sup> L’utilisateur peut également régler la température
            souhaitée à l’avance, permettant de ne pas laisser couler
            inutilement l’eau et générer 5% d’économie d’énergie. Ceci évite
            également les risques de brûlures (grâce à son corps froid, le
            mitigeur reste froid même s’il est traversé par de l’eau chaude ou
            très chaude).
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-10-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des WC également économes en eau</h3>
          <p>
            Enfin tous les WC sont équipés de système de chasse à double touche
            <sup>2</sup>.
          </p>
          <p class="legend">
            <sup>2</sup> Représentant jusqu'à 40% de la consommation d’un foyer
            en eau, les WC sont plus gourmands en eau que vos douches ou que le
            lave-linge et le lave-vaisselle réunis.
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-10-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}
